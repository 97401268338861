import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import type { MediumResponseData, Feed, Post } from '../types';
import Layout from '../components/layout/layout';
import { H1, H2, P, OutboundLink, ButtonLink } from '../components';

function parseFirstParagraph(markup: string) {
  if (!markup) {
    return '';
  }
  const regex = /<p>(.*?)<\/p>/;
  const corresp = regex.exec(markup);
  const firstParagraph = corresp ? corresp[0] : ''; // <p>text1</p>
  // const firstParagraphWithoutHtml = corresp ? corresp[1] : ''; // text1
  return firstParagraph;
}

function parseTeaser(markup: string) {
  const firstParagraph = parseFirstParagraph(markup);
  if (firstParagraph.length > 100) {
    return firstParagraph;
  }
  const restText = markup.replace(firstParagraph, '');
  return firstParagraph + parseTeaser(restText);
}

export default function Stories() {
  const [feed, setFeed] = useState<Feed>(undefined);
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    fetch('/api/medium')
      .then((response) => response.json())
      .then((data: MediumResponseData) => {
        setFeed(data.feed);
        setPosts(data.posts);
      });
  }, []);

  if (!feed) {
    return (
      <Layout title="Stories">
        <div className="w-full px-10 pt-32 md:pt-40 lg:pt-60">
          <div className="flex flex-col justify-center items-center text-center">
            <H1>STORIES</H1>
            <P>...</P>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout title="Stories">
      <div className="w-full px-10 pt-32 md:pt-40 lg:pt-60">
        <div className="flex flex-row gap-10 justify-center items-center text-center">
          <OutboundLink href={feed.url}>
            <H1>{feed.title}</H1>
          </OutboundLink>
        </div>
        <div className="mt-20 flex flex-col gap-10 xl:gap-20">
          {posts.map((post) => (
            <section key={post.guid} className="flex flex-col lg:flex-row gap-10 xl:gap-20 items-center">
              <OutboundLink href={post.link} className="block">
                <img src={post.thumbnail} alt={feed.title} width="400px" />
              </OutboundLink>
              <div className="flex flex-col gap-5">
                <OutboundLink href={post.link}>
                  <H2 className="override:m-0">{post.title}</H2>
                </OutboundLink>
                <div
                  dangerouslySetInnerHTML={{ __html: parseTeaser(post.description) }}
                  className="max-w-3xl text-lg leading-loose"
                />
                <ButtonLink to={post.link} external>
                  Read on Medium
                </ButtonLink>
              </div>
            </section>
          ))}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
